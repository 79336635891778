import React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import SEO from "../components/seo"
import Arrow from "../svgs/Arrow.svg"
import Quote from "../svgs/Quote.svg"

const caseStudy = props => {
  const { pageContext } = props
  const {title, subTitle, text, stats, quote, metaDesc, prevLink, nextLink } = pageContext
  let hasStats;

  if(stats.length > 0) {
    hasStats = true;
  } else {
    hasStats = false;
  }

  return (
    <>
      <SEO title={title} description={metaDesc} pageClass="s-case-study"></SEO>
      <section
        className={
          "c-case-study " + (!hasStats ? "no-stats" : "")
        }
      >
        <div className="c-case-study__links">
          {prevLink && <AniLink cover direction="left" bg="#FF916F" hex="#FF916F" to={prevLink} className="c-case-study__link c-case-study__link--prev">&larr; Previous</AniLink>}
          {nextLink && <AniLink cover direction="right" bg="#FF916F" hex="#FF916F" to={nextLink} className="c-case-study__link c-case-study__link--next">Next &rarr;</AniLink>}
        </div>
        <div className="c-case-study__text">
          <h1>{title}</h1>
          <h2>{subTitle}</h2> 
          <div dangerouslySetInnerHTML={{ __html: text }}></div>
        </div>
        {hasStats &&
          <div className="c-case-study__stats">
            <h5 className="c-case-study__stats-heading">Results have included:</h5>
            <ul className="c-stats">
            {stats.map((stat, index) =>(
              <li key={index} className="c-stats__item">
                <span className="c-stats__callout">
                  <span className="c-stats__number">{stat.number}</span>
                  <span className="c-stats__label">{stat.label}</span>
                </span>
                <Arrow/>
                <span className="c-stats__text" dangerouslySetInnerHTML={{ __html: stat.text }} ></span>
              </li>
            ))}
            </ul>
          </div>
        }
        <div className="c-case-study__quote">
          <Quote/>
          <blockquote>{quote.text}</blockquote>
          {quote.person && <span>{quote.person},</span>}
          {quote.role && <span>{quote.role}</span>}  
          <Quote/>       
        </div>
      </section>
    </>
  )
}
export default caseStudy